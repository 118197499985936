<template>
<div>
  <div v-if="showLoading" class="text-center" style="padding: 24px">
    <CSpinner/>
  </div>

  <div v-if="!showLoading">
    <CRow style="margin-bottom:20px; width:600px">
      <CCol>  
        <CSelect
          label="Periode"
          :options="periodOptionsAll"
          :value.sync="selectedPeriod"
          horizontal
          @change="onChangePeriod()"
        />
      </CCol>
      <CCol>
        <CButton
          color="success"
          variant="outline"
          square
          size="sm"
          @click="onClickNewModule()">
          Topik baru
        </CButton>
      </CCol>
    </CRow>
    <CRow>
      <CDataTable
        :items="items"
        :fields="fields"
        :items-per-page="30"
        hover
        pagination>
        <template #show_module="{item}">
          <td>
            <CLink
              :href="item.moduleDocUrl"
              target="_blank">
              {{ item.moduleName }}
            </CLink>
          </td>
        </template>

        <template #show_source="{item, index}">
          <td style="font-size:small; white-space: pre-wrap;">
            <p>{{item.source}}
            </p>
          </td>
        </template>

        <template #show_objective="{item, index}">
          <td>
            <pre>{{item.objective}}
            </pre>
          </td>
        </template>

        <template #show_editor="{item}">
          <td>
            {{ item.editorName }}
            <CButton
              v-if="item.editorName==user.name"
              color="warning"
              shape="pill"
              variant="outline"
              size="sm"
              @click="onClickEditComplete(item, index)">
              Edit selesai
            </CButton>
            {{ item.editTimeFormatted }}
          </td>
        </template>
        <template #show_validator="{item}">
          <td>
            {{ item.validatorName }}
            <CButton
              v-if="item.validatorName===user.name"
              color="success"
              shape="pill"
              variant="outline"
              size="sm"
              @click="onClickValidateComplete(item, index)">
              Validasi selesai
            </CButton>
            {{ item.validateTimeFormatted }}
          </td>
        </template>
        <template #show_approver="{item}">
          <td>
            {{ item.approverName }}
            <CButton
              v-if="item.approverName===user.name"
              color="danger"
              shape="pill"
              variant="outline"
              size="sm"
              @click="onClickApprove(item, index)">
              Approve
            </CButton>
            {{ item.approveTimeFormatted }}
          </td>
        </template>
        <template #show_edit="{item, index}">
          <td class="py-2">
            <CButton
              color="primary"
              variant="outline"
              square
              size="sm"
              @click="onClickEdit(item, index)">
              Edit
            </CButton>
          </td>
        </template>
        <template #show_delete="{item, index}">
          <td class="py-2">
            <CButton
              color="danger"
              variant="outline"
              square
              size="sm"
              @click="onClickDelete(item, index)">
              Delete
            </CButton>
          </td>
        </template>   
      </CDataTable>
    </CRow>
  </div>

  <CModal :show.sync="showDialogEdit" :title="title" size="lg" hide-footer>
    <div style="padding: 10px">
      <CSelect
        id="selectPeriod"
        label="Periode"
        :options="periodOptions"
        :value.sync="record.studyPeriodId"
        horizontal
      />
      <CSelect
        id="selectSemester"
        label="Semester"
        :options="[1,2,3,4,5,6,7,8]"
        :value.sync="record.semester"
        horizontal
      />
      <CSelect
        id="selectModuleNo"
        label="Nomor Modul"
        :options="[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16]"
        :value.sync="record.moduleNo"
        horizontal
      />
      <CInput
        label="Nama Modul"
        v-model="record.moduleName"
        horizontal
      />
      <CTextarea
        label="Bahan"
        v-model="record.source"
        horizontal
        rows="3"
      />
      <CTextarea
        label="Tujuan"
        v-model="record.objective"
        horizontal
        rows="3"
      />
      <CSelect
        id="nbMeeting"
        label="Jumlah sesi"
        :options="[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20]"
        :value.sync="record.nbMeeting"
        horizontal
      />
      <CSelect
        id="selectEditor"
        label="Editor"
        :options="workerOptions"
        horizontal
      />
      <CSelect
        id="selectValidator"
        label="Validator"
        :options="workerOptions"
        horizontal
      />
      <CSelect
        id="selectApprover"
        label="Approver"
        :options="workerOptions"
        horizontal
      />
      <CInput
        label="Google Doc url"
        v-model="record.moduleDocUrl"
        horizontal
      />
      <CInput
        label="PDF url"
        v-model="record.pdfUrl"
        horizontal
      />
    </div>

    <div slot="footer">
      <CButton @click="dialogEditClose()">Batal</CButton>
      <CButton @click="dialogEditSave()" :disabled="disableButtonOk">Simpan</CButton>
    </div>
  </CModal>
</div>
</template>

<script>
  import api from "../../api/api"

  const fields = [
    { key: 'studyPeriod', label: 'Periode'},
    { key: 'semester', label: 'Sem.'},
    { key: 'moduleNo', label: 'Modul'},
    { key: 'show_module', label: 'Nama', _style:'min-width:100px' },
    { key: 'show_source', label: 'Bahan', _style:'min-width:150px' },
    { key: 'objective', label: 'Tujuan', _style:'min-width:150px' },
    { key: 'nbMeeting', label: '#sesi'},
    { key: 'show_editor', label: 'Editor'},
    { key: 'show_validator', label: 'Validator'},
    { key: 'show_approver', label: 'Approver'},
    { key: 'show_edit', label: '', _style: 'width:1%' },
    { key: 'show_delete', label: '', _style: 'width:1%' }
  ]

  export default {
    name: 'Discipleship',
    data () {
      return {
        title: '',
        isEdit: false,

        showLoading: false,
        itemsAll: [],
        items: [],
        fields,
        showDialogEdit: false,
        record: '',
        workers: [],
        workerOptions: [],

        periods: [],
        periodOptions: [],
        periodOptionsAll: [],
        selectedPeriod: '',
        user: ''
      }
    },

    async created () {
      var idToken = localStorage.getItem('idToken')

      if(idToken===null || idToken===undefined || idToken==='undefined')
        this.$router.replace('/login', () => {})
      else{
        this.user = JSON.parse(localStorage.getItem('user'))
        this.listWorker()
      }
    },

    methods: {
      async listPeriod () {
        this.showLoading = true
        this.items = []
        let apiRes = await api.listPeriod()
        this.showLoading = false

        if (apiRes.result === 'OK') {
          this.periods = apiRes.periods

          this.periodOptions = []
          this.periodOptionsAll = []
          this.periodOptionsAll.push({value: "0", label: "Semua"})

          for(var i=0; i<this.periods.length; i++){
            var option = {value: this.periods[i].id, label: this.periods[i].name}
            this.periodOptions.push(option)
            this.periodOptionsAll.push(option)
          }

          if(this.periods.length>0){
            this.selectedPeriod = this.periods[0].id
            this.listBahanPA()
          }
        } 
        else {
          console.log("listPeriod ERR: " + apiRes.err)
          this.$router.replace('/login', () => {})
        }
      },

      async listWorker () {
        this.showLoading = true
        let apiRes = await api.listWorker()
        this.showLoading = false

        if (apiRes.result === 'OK') {
          this.workers = apiRes.workers
          this.workerOptions = []
          this.workerOptions.push("-")
          for(var i=0; i<apiRes.workers.length; i++){
            this.workerOptions.push(apiRes.workers[i].name)
          }
          this.listPeriod()
        } 
        else {
          console.log("listBahanPA ERR: " + apiRes.err)
          this.$router.replace('/login', () => {})
        }
      },

      async listBahanPA () {
        this.showLoading = true
        let apiRes = await api.listBahanPA("ALL")
        this.showLoading = false

        if (apiRes.result === 'OK') {
          this.itemsAll = apiRes.data.items

          this.onChangePeriod()
        } 
        else {
          console.log("listBahanPA ERR: " + apiRes.err)
          this.$router.replace('/login', () => {})
        }
      },

      getBadge (status) {
        switch (status) {
          case 'ADMIN': return 'success'
          default: 'primary'
        }
      },

      onClickEdit (item) {
        this.title = "Edit Topik Persekutuan"
        this.isEdit = true

        this.record = item
        document.getElementById("selectSemester").selectedIndex = item.semester - 1

        document.getElementById("selectEditor").selectedIndex = 0
        document.getElementById("selectValidator").selectedIndex = 0
        document.getElementById("selectApprover").selectedIndex = 0

        for(var i=0; i<this.workers.length; i++){
          if(this.workers[i].id === item.editorId)
            document.getElementById("selectEditor").selectedIndex = i+1

          if(this.workers[i].id === item.validatorId)
            document.getElementById("selectValidator").selectedIndex = i+1

          if(this.workers[i].id === item.approverId)
            document.getElementById("selectApprover").selectedIndex = i+1
        }
        this.showDialogEdit = true
      },

      async onClickDelete (item) {
        this.record = item
        this.$confirm("Yakin untuk menghapus Modul ini?").then(() => {
          this.executeDeleteBahanPa()
        });
      },

      async executeDeleteBahanPa(){
        console.log("=>RECORD: " + JSON.stringify(this.record))

        this.showLoading = true
        let apiRes = await api.deleteBahanPA(this.user.id, this.record.id)
        this.showLoading = false

        if (apiRes.result === 'OK') {
          this.$alert("Modul sudah dihapus!")
          this.listBahanPA()
        } 
        else {
          console.log("deleteBahanPa ERR: " + apiRes.err)
          this.$router.replace('/login', () => {})
        }      
      },

      dialogEditClose(){
        this.showDialogEdit = false
      },

      async dialogEditSave(){
        if(this.record.moduleName==='' || this.record.source==='' || this.record.objective==='' || this.record.moduleDocUrl==='')
          this.$alert("Nama modul, bahan, tujuan dan Google Doc URL harus diisi")
        else{
          this.record.studyPeriodId = this.periods[document.getElementById("selectPeriod").selectedIndex].id

          console.log("document.getElementById(selectPeriod).selectedIndex: " + document.getElementById("selectPeriod").selectedIndex)
          console.log("this.record.studyPeriodId: " + this.record.studyPeriodId)

          this.record.editorId = "0"
          this.record.editorName = "-"
          if(document.getElementById("selectEditor").selectedIndex > 0){
            this.record.editorId = this.workers[document.getElementById("selectEditor").selectedIndex-1].id
            this.record.editorName = this.workers[document.getElementById("selectEditor").selectedIndex-1].name
          }

          this.record.validatorId = "0"
          this.record.validatorName = "-"
          if(document.getElementById("selectValidator").selectedIndex > 0){
            this.record.validatorId = this.workers[document.getElementById("selectValidator").selectedIndex-1].id
            this.record.validatorName = this.workers[document.getElementById("selectValidator").selectedIndex-1].name
          }

          this.record.approverId = "0"
          this.record.approverName = "-"
          if(document.getElementById("selectApprover").selectedIndex > 0){
            this.record.approverId = this.workers[document.getElementById("selectApprover").selectedIndex-1].id
            this.record.approverName = this.workers[document.getElementById("selectApprover").selectedIndex-1].name
          }

          if(this.record.pdfUrl===undefined) this.record.pdfUrl = ""

          this.showDialogEdit = false

          this.showLoading = true
          var apiRes = null
          if(this.isEdit)
            apiRes = await api.updateBahanPA(this.user.id, this.record)
          else
            apiRes = await api.createBahanPA(this.user.id, this.record)
          this.showLoading = false

          if (apiRes.result === 'OK') {
            if(this.isEdit)
              this.$alert("Update modul OK!")
            else
              this.$alert("Modul baru sudah dibuat!")
            this.listBahanPA()
          } 
          else {
            console.log("dialogEditSave ERR: " + apiRes.err)
            this.$router.replace('/login', () => {})
          }
        }
      },

      async onClickEditComplete(item, index){
        this.$confirm("Pastikan EDIT sudah selesai sebelum melanjutkan!").then(() => {
          this.executeEditComplete(item)
        });
      },

      async executeEditComplete(item){
        this.showLoading = true
        let apiRes = await api.updateDateBahanPA(this.user.id, 'EDIT', item.id)
        this.showLoading = false

        if (apiRes.result === 'OK') {
          this.$alert("Update EDIT OK!")
          this.listBahanPA()
        } 
        else {
          console.log("onClickEditComplete ERR: " + apiRes.err)
          this.$router.replace('/login', () => {})
        }
      },

      async onClickValidateComplete(item, index){
        this.$confirm("Pastikan VALIDASI sudah selesai sebelum melanjutkan!").then(() => {
          this.executeValidateComplete(item)
        });
      },

      async executeValidateComplete(item){
        this.showLoading = true
        let apiRes = await api.updateDateBahanPA(this.user.id, 'VALIDATE', item.id)
        this.showLoading = false

        if (apiRes.result === 'OK') {
          this.$alert("Update VALIDATE OK!")
          this.listBahanPA()
        } 
        else {
          console.log("onClickEditComplete ERR: " + apiRes.err)
          this.$router.replace('/login', () => {})
        }
      },

      async onClickApprove(item, index){
        this.$confirm("Pastikan seluruh dokumen sudah di-cek sebelum melanjutkan!").then(() => {
          this.executeApprove(item)
        });
      },

      async executeApprove(item){
        this.showLoading = true
        let apiRes = await api.updateDateBahanPA(this.user.id, 'APPROVE', item.id)
        this.showLoading = false

        if (apiRes.result === 'OK') {
          this.$alert("Update APPROVE OK!")
          this.listBahanPA()
        } 
        else {
          console.log("onClickEditComplete ERR: " + apiRes.err)
          this.$router.replace('/login', () => {})
        }
      },

      onChangePeriod(){
        this.items = []
        if(this.selectedPeriod==="0")
          this.items = this.itemsAll
        else{
          for(var i=0; i<this.itemsAll.length; i++){
            if(this.itemsAll[i].studyPeriodId===this.selectedPeriod) this.items.push(this.itemsAll[i])
          }
        }
      },

      onClickNewModule(){
        this.title = "Buat Modul Baru"
        this.isEdit = false

        this.record = {
          studyPeriodId: this.periodOptions[0].value,
          semester: 1,
          moduleNo: 1,
          moduleName: '',
          source: '',
          objective: '',
          nbMeeting: 1,
          editorName: '-',
          validatorName: '-',
          approverName: '-',
          moduleDocUrl: '',
          pdfUrl: ''
        }
        this.showDialogEdit = true
      },
    },

    computed: {
      disableButtonOk () {
        return false
        //return this.record.note && this.record.note.length===0
      }
    },
  }
</script>
